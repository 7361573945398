<template>
  <div>
    <a
      href="#"
      @click.prevent="pricing.push({})"
      class="btn btn-primary btn-sm float-end"
      ><i class="lni lni-plus me-2"></i>Add Pricing</a
    >
    <h3>Pricing Management - {{ $route.params.type }}</h3>
    <loading v-if="loading" />
    <div class="card" v-if="!loading">
      <div class="card-body">
        <form @submit.prevent="submit">
          <table class="table table-bordered">
            <thead>
              <tr class="text-center">
                <th width="30%"></th>
                <th v-for="(price, i) in pricing" :key="`thead-${i}`">
                  <a
                    href="#"
                    @click.prevent="pricing.splice(i, 1)"
                    class="text-danger"
                    ><i class="lni lni-trash-can"></i
                  ></a>
                </th>
                <th></th>
              </tr>
              <tr>
                <th width="30%">Title</th>
                <th v-for="(price, i) in pricing" :key="`thead-${i}`">
                  <input
                    type="text"
                    v-model="price.title"
                    class="form-control"
                    required
                    placeholder="Title"
                  />
                </th>
                <th></th>
              </tr>
              <tr>
                <th width="30%">Amount</th>
                <th v-for="(price, i) in pricing" :key="`thead-${i}`">
                  <input
                    type="text"
                    v-model="price.amount"
                    class="form-control"
                    required
                    placeholder="Amount"
                  />
                </th>
                <th></th>
              </tr>
              <tr>
                <th class="text-center" :colspan="pricing.length + 2">
                  Features
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(line, i) in content_lines" :key="`row-${i}`">
                <td>
                  <input
                    type="text"
                    placeholder="Name"
                    v-model="content_lines[i]"
                    class="form-control"
                    required
                  />
                </td>
                <td v-for="(price, j) in pricing" :key="`line-${j}`">
                  <input
                    type="text"
                    placeholder="Value"
                    v-model="price.content[i]"
                    class="form-control"
                    required
                  />
                </td>
                <td>
                  <a href="#" class="text-danger" @click.prevent="removeLine(i)"><i class="lni lni-trash-can"></i></a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="#" @click.prevent="content_lines.push('')"
                    ><i class="lni lni-plus me-2"></i> Add Feature</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <button class="btn btn-primary">
            <i class="lni lni-save me-2"></i>Save
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      pricing: [],
      loading: true,
      content_lines: [],
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.axios.get(`/api/v1/admin/pricing/${this.$route.params.type}`).then((response) => {
        this.pricing = response.data.pricing;
        this.content_lines = response.data.content_lines;
        this.loading = false;
      });
    },

    submit() {
      let data = this.pricing.map((price) => {
        let content = {};

        price.content.forEach((item, i) => {
          content[this.content_lines[i]] = item;
        });

        return {
          id: price.id,
          title: price.title,
          amount: price.amount,
          content: content,
        };
      });

      this.loading = true
      this.axios.post('/api/v1/admin/pricing', data).then(() => {
        this.loading = false
        this.$router.push({ name: 'admin.pricing.index' })
      }).catch(() => {
        this.loading = false
      })
    },

    removeLine(i) {
      this.content_lines.splice(i, 1)

      this.pricing = this.pricing.map(price => {
        price.content.splice(i, 1)

        return price
      })
    }
  },

  computed: {},
};
</script>
